<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div class="fidtop">
      <div><span>配件订单 </span> <span class="dds">{{ form.billNumber }}</span></div>
    </div>
    <!-- <van-nav-bar :title="' ' + form.billNumber" left-arrow :fixed="true" :z-index="999" @click-left="back"> -->

    <!-- </van-nav-bar> -->

    <div class="tops">
      <span style="color: #666;" @click="rems">供应商</span>
      <span>{{ form.supplierName }}</span>
      <!-- <span class="flxdd" v-if="mengdian != 0">{{ form.unPayCounts }}笔未付款<van-icon name="arrow" /></span> -->
    </div>

    <div class="topsdiv">
      <div class="cendiv">
        <van-uploader :after-read="onReadfkm" :name="1">
          <van-button size="mini" icon="plus" type="primary">上传</van-button>
        </van-uploader>
        <div class="scsdiv" v-if="form.orderPic !== undefined && form.orderPic.length == 0">商家上传</div>
        <van-swipe @change="onChanges" v-else class="swipe">
          <van-swipe-item v-for="(item, idx) in form.orderPic" :key="idx" @click="toimgs(form.orderPic, idx)">
            <img class="scsdiv" :src="item.pic" alt="">
            <img class="guanbi" @click.stop="dltimg(item.id, idx, 1)" src="../../../assets/img/guan2.png" alt="">
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ currents + 1 }}/{{ form.orderPic !== undefined && form.orderPic.length }}
            </div>
          </template>
        </van-swipe>

        <div>订货单据</div>
        <div class="namess">
          <span v-if="form.orderPic !== undefined && form.orderPic.length != 0"> {{ form.orderPic[0].name }}</span>
        </div>
      </div>
      <div class="cendiv">
        <!-- :disabled="mengdian == 0" -->
        <van-uploader :max-count="1" :after-read="onReadfkm"  :name="2">
          <van-button size="mini" icon="plus" type="primary" >上传</van-button>
        </van-uploader>
        <div class="scsdiv" v-if="form.payCode" style="position: relative;">
          <img class="scsdiv" @click="toimg(form.payCode)" :src="form.payCode" alt="">
          <img class="guanbi" v-if="mengdian != 0" @click.stop="setaddpic('')" src="../../../assets/img/guan2.png" alt="">
        </div>
        <div class="scsdiv" v-else>财务上传</div>
        <div>付款码</div>
        <div class="namess">
          <span v-if="form.payCodeName"> {{ form.payCodeName }}</span>
        </div>
      </div>
      <div class="cendiv">
        <van-uploader :after-read="onReadfkm"  :name="3">
          <van-button size="mini" icon="plus" type="primary">上传</van-button>
        </van-uploader>
        <div class="scsdiv" v-if="form.payPic !== undefined && form.payPic.length == 0">财务上传</div>
        <van-swipe @change="onChange" v-else class="swipe">
          <van-swipe-item v-for="(item, idx) in form.payPic" :key="idx" @click="toimgs(form.payPic, idx)">
            <img class="scsdiv" :src="item.pic" alt="">
            <img class="guanbi" v-if="mengdian != 0" @click.stop="dltimg(item.id, idx, 3)"
              src="../../../assets/img/guan2.png" alt="">
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{ form.payPic !== undefined && form.payPic.length }}</div>
          </template>
        </van-swipe>
        <div>付款截图</div>
        <div class="namess">
          <span v-if="form.payPic !== undefined && form.payPic.length != 0"> {{ form.payPic[0].name }}</span>
        </div>
      </div>
    </div>
    <div>


    </div>
    <van-row class="topsdiv" type="flex" justify="space-between">
      <van-col span="10" class="flxsb">
        <span style="color: #666;">车牌：</span>
        <span>{{ carNo }}</span>
      </van-col>
      <van-col span="11" class="flxsb">
        <span style="color: #666;">车型：</span> <span>{{ form.carModel }}</span>
      </van-col>
    </van-row>
    <!-- <van-cell-group class="bot" style="border-bottom: 4px solid #f5f5f5;">
      <van-field readonly v-model="form.carVin" clearable label="车架号" placeholder="">
        <template #button>
          <div class="left">
            <img class="che_img" style="margin-top: 3px;" v-if="form.vinPic" :src="form.vinPic" alt="">
            <img class="che_img" style="margin-top: 3px;" v-else src="../../../assets/img/mendian.png" alt="">
          </div>

        </template>
      </van-field>
      <van-field readonly v-model="form.carModel" label="车型" placeholder="">
      </van-field>
    </van-cell-group> -->
    <div class="sctest">
      <div class="reds">
        <span class="hdsiz">货款：</span>
        <span class="ycdiv res">{{ heji ? heji.toFixed(2) : '0.00' }}</span>
        <!-- <span v-if="mengdian ==0">请上传货款收据:</span>
        <span v-else>货款收据图片:</span>
        <div>￥{{heji?heji.toFixed(2):'0.00'}}</div> -->
      </div>
      <div class="reds">
        <span class="hdsiz">已付：</span>
        <span class="ycdiv lvs" @click="setHK()">{{ form.payPrice ? Number(form.payPrice).toFixed(2) : '0.00' }}</span>
      </div>
      <!-- <van-uploader :max-count="1" :after-read="onRead" v-if="!picimg">
        <div class="scdiv">
          <van-icon class="jia" name="plus" size="40" />
        </div>
      </van-uploader>

      <div v-else style="position: relative;">
        <img class="scdiv" @click="getImg(picimg)" :src="picimg" alt="" />
        <img @click="dltimg" class="shangchuimg" src="../../../assets/img/guan2.png" alt="">
      </div>
      <div id="preview" class="pase-div" v-on:paste="handlePaste">
        <div>快捷上传</div>
        <span>将图片按Ctrl+V 粘贴至此处</span>
      </div> -->
    </div>
    <div style="overflow: hidden;background: #fff;padding-top:8px">

      <div class="lists" v-for="(items, idx) in list" :key="idx">
        <div v-for="(item, index) in items.part" :key="index">
          <div class="flxs">
            <div class="fxs">
              <span class="shuzi">{{ index + 1 }}</span>
              <span>{{ item.partName }}</span>
            </div>
            <div class="shuliang" @click="setadd(item)">
              {{ item.partCount }}
            </div>
          </div>
          <div class="fdiv">
            配件描述：{{ item.remark }}
          </div>
          <div class="flxs" style=" padding-top: 10px;">
            <div class="ycdiv" @click="setPname(idx, index)">
              {{ item.classify }}
            </div>
            <div>
              <span>￥</span>
              <input class="ipss" v-model="item.price" type="text">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height:50px"></div>
    <!-- <van-button round class="botti" v-if="mengdian ==0 " @click="toset" type="danger">确认上传</van-button> -->
    <div v-if="mengdian == 0"></div>
    <div class="botti" v-else>
      <div class="flsssd">
        <!-- <van-button class="vanbot" @click="toSH" type="info">价格导入</van-button> -->
        <van-button class="vanbot" @click="setzhi" type="danger">生成支出账单</van-button>
        <van-button class="vanbot" style="flex:0.6" @click="setdakuan" :disabled="payisok" type="warning">{{ payisok ?
          '已打款' : '打款' }}</van-button>
      </div>

    </div>
    <van-popup v-model="show3" position="bottom" :style="{ height: '48%' }">
      <van-picker title="选择类型" show-toolbar :columns="actions" @confirm="onSelect" value-key="groupName"
        @cancel="show3 = false" />
    </van-popup>
    <van-popup class="jintan" v-model="dashow">
      <div>打款金额</div>
      <div class="flxx">
        <input class="ipos" type="number" v-model="cashAmount" />
      </div>
      <div class="flss">
        <van-button type="danger" size="small" @click="dashow = false">取 消</van-button>
        <van-button type="primary" size="small" @click="setjiner">提 交</van-button>
      </div>
    </van-popup>
    <van-popup class="jintan" v-model="xgHKshow">
      <div>修改已付货款</div>
      <div class="flxx">
        <input class="ipos" type="number" v-model="hkprice" />
      </div>
      <div class="flss">
        <van-button type="danger" size="small" @click="xgHKshow = false">取 消</van-button>
        <van-button type="primary" size="small" @click="setupdateprice">提 交</van-button>
      </div>
    </van-popup>
    <van-popup class="jintan" v-model="xgshow">
      <div>修改 {{ pjform.partName }} 数量</div>
      <div class="flxx">
        <input class="ipos" type="number" v-model="pjform.partCount" />
      </div>
      <div class="flss">
        <van-button type="danger" size="small" @click="xgshow = false">取 消</van-button>
        <van-button type="primary" size="small" @click="setaddQd">提 交</van-button>
      </div>
    </van-popup>
    <div class="zd" v-if="form.paymentStatus == 1">已生成支出账单</div>
    <!-- <van-action-sheet @select="onSelect" v-model="show3" :actions="actions" value-key="groupName" cancel-text="取消" close-on-click-action /> -->
  </div>
</template>

<script>
import {
  uploadPicture,
  askOrderList,
  checkCash,
  paymentAccount,
  savePic,
  getGarageInfo,
  garageAskPriceupdate,
  XJcheckAuth,
  workTypelist,
  sendSupplier,
  partcounts,
  addpic,
  askPricepic,
  deletepic,
  updateprice,
} from "@/api/check";
import { ImagePreview } from "vant";
import moment from "moment";
import preventReClick from "@/assets/preventReClick";
import { runInThisContext } from "vm";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [], //
      user: JSON.parse(localStorage.getItem("userInfo")),
      token: "",
      current: 0,
      currents: 0,
      hkprice: "",
      xgshow: false,
      xgHKshow: false,
      name: "",
      cashAmount: "",
      show3: false,
      payisok: true,
      dashow: false,
      actions: [],
      form: {},
      carNo: "",
      isok: false,
      show: false,
      mengdian: 0,
      oid: "",
      gid: "",
      supplierId: "",
      picimg: null,
      denglu: false,
      groupName: "",
      groupId: "",
      formName: {},
      heji: 0,
      windowHeight: document.documentElement.clientHeight - 130,
      pjform: {},
    };
  },
  methods: {
    rems() {
      localStorage.removeItem('Authorization')
    },
    toimg(img) {
      // console.log(img);
      // var img = [];
      // for (var i in item) {
      //   img.push(item[i].picUrl);
      // }
      // return
      this.$router.push({
        name: "showimg",
        query: {
          img: img,
          idx: 0,
        },
      });
    },
    //修改付款
    setupdateprice() {
      var data = {
        oid: this.oid,
        price: this.hkprice,
        supplierId: this.form.supplierId,
      };
      updateprice(data).then((e) => {
        if (e.code == 200) {
          this.form.payPrice = this.hkprice;
          this.$toast.success("修改成功");
          this.xgHKshow = false;
        }
      });
    },
    toimgs(item, idx) {
      console.log(img);
      var img = [];
      for (var i in item) {
        img.push(item[i].pic);
      }
      // return
      this.$router.push({
        name: "showimg",
        query: {
          img: img.join(","),
          idx: idx,
        },
      });
    },
    setadd(item) {
      if (this.mengdian == 0) {
        return;
      }
      this.pjform = JSON.parse(JSON.stringify(item));
      this.xgshow = true;
    },
    onChange(index) {
      this.current = index;
    },
    onChanges(index) {
      this.currents = index;
    },
    //修改货款
    setHK() {
      if (this.mengdian == 0) {
        return;
      }
      this.hkprice = this.form.payPrice;
      this.xgHKshow = true;
    },
    setaddQd() {
      var data = {
        askPriceId: this.pjform.askPriceId,
        partCount: this.pjform.partCount,
        partId: this.pjform.partId,
      };
      partcounts(data).then((e) => {
        if (e.code == 200) {
          this.onLoad();
          this.$toast.success("修改成功");
          this.xgshow = false;
        }
      });
    },
    setzhi() {
      if (this.mengdian != 2) {
        this.$toast("你没有权限！");
        return;
      }
      if (this.form.paymentStatus == 1) {
        this.$toast("请勿重复生成支出账单！");
        return;
      }
      this.show3 = true;
    },

    async onReadfkm(file, detail) {

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      // alert(sessionStorage.getItem('h5token'))
      var _this = this;
      console.log(file);
      console.log(detail);
      // return
      // console.log(file.file);
      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);
      // alert(sessionStorage.getItem('h5token'))

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e);
          // this.$toast.success("上传图片成功");
          // this.picimg = e.data;
          if (detail.name == 1) {
            _this.setaskPricepic(e.data, 0);
          } else if (detail.name == 2) {
            _this.setaddpic(e.data);
          } else {
            _this.setaskPricepic(e.data, 1);
          }
        }
      });
    },
    //上传订货单/支付单据
    setaskPricepic(img, type) {
      var data = {
        supplierId: this.form.supplierId,
        oid: this.oid,
        type: type,
        pic: img,
      };
      askPricepic(data).then((e) => {
        if (e.code == 200) {
          console.log(e);
          this.$toast.success("上传成功");
          this.onLoad();
        }
      });
    },
    //上传付款码
    setaddpic(img) {
      var data = {
        supplierId: this.form.supplierId,
        gid: this.gid,
        pic: img,
      };
      addpic(data).then((e) => {
        if (e.code == 200) {
          console.log(e);
          if (img == "") {
            this.$toast.success("删除成功");
          } else {
            this.$toast.success("上传成功");
          }

          this.form.payCode = img;
        }
      });
    },
    async onRead(file, detail) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      // alert(sessionStorage.getItem('h5token'))
      var _this = this;
      // console.log(file);
      // console.log(detail)
      // console.log(file.file);
      let files = this.dataURLtoFile(file.content, file.file.name);
      let formdata1 = new FormData();
      formdata1.append("file", files);
      // alert(sessionStorage.getItem('h5token'))

      uploadPicture(formdata1).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e);
          this.$toast.success("上传图片成功");
          this.picimg = e.data;
        }
      });
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.groupName = item.groupName;
      this.groupId = item.id;
      this.toshengcheng();
      console.log(item);
    },
    //生成账单
    toshengcheng() {
      var data = {
        billId: this.form.id,
        // agentName: this.user.userName,
        paymentAmount: this.heji,
        billNumber: this.form.billNumber,
        billType: 2,
        groupName: this.groupName,
        groupId: this.groupId,
        carNo: this.carNo,
        oid: this.oid,
        paymentDate: moment().format("YYYY-MM-DD HH:MM:SS"),
        gid: this.gid,
        supplierId: this.form.supplierId,
        supplierName: this.form.supplierName,
        remark: "询价配件费",
        pictureList: [
          {
            picUrl: this.picimg,
          },
        ],
      };
      // console.log(data);
      // return;
      paymentAccount(data).then((e) => {
        if (e.code == 200) {
          // console.log(e);
          this.$toast.success("生成账单成功");
          this.form.paymentStatus = 1;
          this.show3 = false;
          // this.picimg = e.data;
        }
      });
    },
    //提交打款
    setjiner() {
      var _this = this;
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认打款" + this.cashAmount + "元?",
        })
        .then(() => {
          var data = {
            cashAmount: this.cashAmount,
            supplierName: this.form.supplierName,
            supplierId: this.supplierId,
            carNo: this.carNo,
            gid: this.gid,
            oid: this.oid,
          };
          sendSupplier(data).then((e) => {
            if (e.code == 200) {
              // console.log(e);
              _this.$toast.success("打款成功");
              _this.dashow = false;
              _this.getpayno();
              // this.picimg = e.data;
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    toSH() {
      garageAskPriceupdate(this.form).then((e) => {
        if (e.code == 200) {
          // console.log(e);
          this.$toast.success("导入成功");
          // this.picimg = e.data;
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    dltimg(id, idx, type) {
      deletepic(id).then((e) => {
        if (e.code == 200) {
          if (type == 1) {
            this.form.orderPic.splice(idx, 1);
          } else {
            this.form.payPic.splice(idx, 1);
          }
          this.$toast.success("删除成功");
        }
      });
    },
    //打款弹窗
    setdakuan() {
      this.cashAmount = this.heji;
      this.dashow = true;
    },
    toset() {
      if (!this.picimg) {
        this.$toast("请先上传图片！");
        return;
      }
      var list = this.list[0].part;
      var ids = [];
      for (var i = 0; i < list.length; i++) {
        ids.push(list[i].id);
      }
      var dto = {
        askPriceId: this.list[0].id,
        ids: ids,
      };
      var data = {
        oid: this.oid,
        supplierId: this.supplierId,
        pic: this.picimg,
        dto: dto,
        carNo: this.carNo,
        billNumber: this.list[0].billNumber,
        askId: this.list[0].id,
      };
      savePic(data).then((e) => {
        if (e.code == 200) {
          this.$toast.success("提交成功");
        }
      });
    },

    //点击放大图片
    getImg(images) {
      ImagePreview([images]);
    },
    getworkTypelist() {
      workTypelist().then((e) => {
        if (e.code == 200) {
          this.actions = e.data;
          console.log(e);
        }
      });
    },
    //校验权限
    getXJcheckAuth() {
      XJcheckAuth({ gid: this.gid }).then((e) => {
        if (e.code == 200) {
          this.mengdian = e.data;
        }
      });
    },
    onLoad() {
      var _this = this;
      const data = {
        supplierId: this.supplierId,
        oid: this.oid,
        carNo: this.carNo,
      };
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      askOrderList(data)
        .then((e) => {
          loading.clear();

          var heji = 0;
          var datas = e.data;
          for (var i in datas) {
            for (var j in datas[i].part)
              heji += datas[i].part[j].price * datas[i].part[j].partCount;
          }
          this.heji = heji;
          this.list = e.data;
          this.form = e.data[0];
          this.picimg = e.data[0].pic;
          console.log(this.form);
        })
        .catch(() => loading.clear());
    },
    getpayno() {
      var data = {
        oid: this.oid,
        supplierId: this.supplierId,
      };
      checkCash(data).then((e) => {
        if (e.code == 200) {
          this.payisok = e.data;
          console.log(e);
        }
      });
    },
    back() {
      parent.WeixinJSBridge.call("closeWindow");
    },
    // 监听粘贴操作
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;
      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        this.$message.error("粘贴内容非图片");
        return;
      }
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "上传中...",
      });
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      const reader = new FileReader();

      var _this = this;
      reader.onload = (event) => {
        // preview.innerHTML = `<img id="pase-img" src="${event.target.result}" style="width: 100%">`; // 添加style样式保证图片等比缩放
        let files = _this.dataURLtoFile(event.target.result, file.name);
        let formdata1 = new FormData();
        formdata1.append("file", files);
        uploadPicture(formdata1).then((e) => {
          loading.clear();
          if (e.code == 200) {
            console.log(e);
            _this.$toast.success("上传图片成功");
            _this.picimg = e.data;
          }
        });
      };
      reader.readAsDataURL(file);
      console.log(reader);
      console.log(file);

      this.pasteFile = file;
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              // alert('走了这里' +  _this.token)
              _this.onLoad();
              _this.getXJcheckAuth();
              _this.getworkTypelist();
              _this.getpayno();
              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        // alert(_this.token)
        _this.onLoad();
        _this.getXJcheckAuth();
        _this.getworkTypelist();
        _this.getpayno();
        console.log("andriod");
      }
    },
  },
  created() {
    this.supplierId = this.$route.query.supplierId;
    this.oid = this.$route.query.oid;
    this.gid = this.$route.query.gid;
    this.carNo = this.$route.query.carNo;
    var app = this.$route.query.app;
    if (app == "true") {
      return;
    }
    // return;
    var url =
      this.$route.path +
      "?gid=" +
      this.gid +
      "&oid=" +
      this.oid +
      "&carNo=" +
      this.carNo +
      "&supplierId=" +
      this.supplierId +
      "&denglu=" +
      true;
    localStorage.setItem("beforeUrl", url);
    this.denglu = this.$route.query.denglu;


    sessionStorage.setItem("Agid", this.gid);
    sessionStorage.setItem("gid", this.gid);
    getGarageInfo({
      gid: this.gid,
    })
      .then((e) => {
        // loading.clear();
        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        if (localStorage.getItem('Authorization')) {
          this.onLoad();
          this.getXJcheckAuth();
          this.getworkTypelist();
          this.getpayno();

        } else {
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          // window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

          return;
        }
        // 正式

      })
      .catch(() => loading.clear());




    // this.handlePaste();
  },
  mounted() { },
  computed: {},
};
</script>

<style lang="less" scoped>
body {
  font-size: 14px;
}

.namess {
  font-size: 12px;
  color: #666;
  line-height: 16px;
  height: 16px;
}

.fidtop {
  height: 46px;
  width: 100%;
  position: relative;
  background-color: #fff;
  z-index: 999;
  line-height: 46px;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid #f6f6f6;

  .dds {
    font-size: 13px;
  }
}

.custom-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0px 5px;
  line-height: 16px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.7);
}

.pase-div {
  width: 110px;
  height: 80px;
  border: 1px dashed #c0ccda;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  color: #666;
  padding: 5px 0;
  margin-left: 20px;
}

.flxdd {
  display: flex;
  align-items: center;
  color: red;
}

.tops {
  font-size: 13px;

  /* margin-top: 46px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;

  border-bottom: 4px solid #f5f5f5;
}

.guanbi {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
}

.topsdiv {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-bottom: 4px solid #f5f5f5;

  .cendiv {
    text-align: center;
    font-size: 12px;
  }

  .scsdiv {
    width: 90px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dcdcdc;
    font-size: 13px;
    color: #999;
  }

  .flxsb {
    display: flex;
    justify-content: space-between;
  }

  .swipe {
    width: 90px;
    height: 60px;
  }

  .van-button {
    width: 90px;
    margin-bottom: 5px;
  }
}

.ls_top {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  font-weight: bold;
  color: #1989fa;
}

.lis_row {
  padding: 0 12px;
  line-height: 30px;
  font-size: 13px;
}

.lists {
  border-bottom: 2px solid #f5f5f5;
  font-size: 13px;
}

.flxs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 4px 15px;

  .fxs {
    display: flex;
    align-items: center;
  }

  .shuzi {
    color: red;
    border: 1px solid red;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    // display: inline-block;
  }

  .shuliang {
    background: #f5f5f5;
    padding: 0 12px;
  }

  .ipss {
    width: 120px;
    background: #f5f5f5;
    border: none;
    padding: 0 10px;
  }
}

.cet {
  text-align: center;
}

.fdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  // margin-top: 10px;
}

.ris {
  text-align: right;
}

.flsssd {
  display: flex;
  align-items: center;
}

.botti {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.vanbot {
  flex: 1;
  border-radius: 0;
}

.fdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  // margin-top: 10px;
}

.jintan {
  width: 270px;
  border-radius: 4px;
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 18px;

  .flxx {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    font-size: 16px;
  }

  .flss {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .van-button {
      flex: 1;
      height: 38px;
    }
  }
}

.ipos {
  width: 120px;
  height: 32px;
  color: red;
  text-align: center;
}

.ycdiv {
  width: 80px;
  background: #f5f5f5;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 26px;
}

.sctest {
  font-size: 15px;
  line-height: 40px;
  padding: 8px 12px;
  // border-top: 4px solid #f5f5f5;
  border-bottom: 4px solid #f5f5f5;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .hdsiz {
    font-size: 13px;
  }

  .res {
    color: red;
  }

  .lvs {
    color: #117b1f;
  }

  .reds {
    display: flex;
    align-items: center;
  }
}

.scdiv {
  // width: 350px;
  display: block;
  height: 80px;
  width: 80px;
  border: 1px solid #dcdcdc;
  margin-left: 10px;
  position: relative;
}

.jia {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  color: #999;
}

.shangchuimg {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  z-index: 9;
  // margin-right: 15px;
}

.che_img {
  width: 24px;
  height: 24px;
}

.cheimgs {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.bot {
  border-top: 4px solid #f6f6f6;
}

.zd {
  font-size: 30px;
  color: red;
  border: 2px solid red;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  margin-top: -10px;
  transform: rotate(-30deg);
  line-height: 45px;
  text-align: center;
  opacity: 0.2;
}
</style>
<style>
/*prettier-ignore */
html {
  font-size: 40PX !important;
}

.van-uploader {
  /* margin: auto; */
  display: block;
}

.van-uploader__wrapper {
  display: block;
}
</style>

